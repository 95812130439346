.player-page {
	
	margin: 10px 0 0;
	padding: 0;
	
	@media screen and (min-width: 992px) {
	margin: 0;
	background: var(--secondary-color) url("/Assets/Images/Backgrounds/player_details_bg.png") 80% 0 no-repeat;
	}
	
}


#player-details-main-data-component {
	
	padding: 15px 20px;
	background: var(--secondary-color);
	
	@media screen and (min-width: 992px) {
	padding: 20px 40px 30px;
	background: none;
	}
	
	.player-team-logo,
	.player-main-data {}
	
	.player-team-logo {
		
		display: none;
		
		@media screen and (min-width: 992px) {
		display: block;
		margin-bottom: 0;
		padding: 0 30px 0 15px;
		}
		
		a {
			
			display: flex;
			width: 100%;
			padding: 15px;
			text-align: center;
			align-content: center;
			align-items: center;
			justify-content: center;
			
			@media screen and (min-width: 992px) {
			min-height: 100px;
			}
			
			img {
				max-width: 60px;
				max-height: 60px;

				@media screen and (min-width: 992px) {
				max-width: 100%;
				max-height: 120px;
				}
				
			}
			
			&:hover {
				opacity: 0.7;
			}
			
		}
	}
	
	.player-main-data {
		
		margin-bottom: 10px;
		padding-left: 0;
		
		@media screen and (min-width: 992px) {
		margin-bottom: 0;
		padding-top: 30px;
		padding-left: 50px;
		}
		
		.player-avatar,
		.player-information {}
		
		.player-avatar {
			
			padding: 0 10px;
			text-align: center;
			@media screen and (min-width: 992px) {
			padding: 0 15px;
			}
			
			.player-avatar-picture {
				
				display: flex;
				justify-content: center;
				align-content: center;
				overflow: hidden;
				width: 80px;
				height: 80px;
				margin: 0 calc(50% - 45px);
				background: var(--white-color);
				border: 5px solid var(--primary-color);
				border-radius: 200px;
				
				@media screen and (min-width: 992px) {
				width: 180px;
				height: 180px;
				margin: 0 calc(50% - 100px);
				border-width: 7px;
				}
				
				img {
					width: auto;
					height: auto;
					@media screen and (min-width: 992px) {
						width: auto;
						height: auto;
					}
				}
				
			}
			
			
			
		}
		
		.player-information {
			
			margin-top: 5px;
			padding-right: 0;
			padding-left: 10px;
			
			@media screen and (min-width: 992px) {
			margin-top: 0;
			padding-left: 35px;
			padding-right: 15px;
			}
			
			> span:not(.player-field-number), > a {
				
				margin-right: 10px;
				padding-right: 10px;
				border-right: 1px solid #FFF;
				
				@media screen and (min-width: 992px) {
				border-width: 2px;
				}
				
				&:last-child {
					margin-right: 0;
					padding-right: 0;
					border-right: none;
				}
				
			}
			
			.player-field-information {
				
				.player-information {
					@media screen and (min-width: 992px) {
					display: flex;
					align-content: flex-start;
					align-items: flex-start;
					justify-content: left;
					padding-left: 35px;
					}
				}
				
				
			}
			
			.player-team-name,
			.player-field-position,
			.player-field-number {
				display: inline-block;
				margin-bottom: 5px;
				font-size: 14px;
				font-weight: 500;
				color: #FFF;
				text-transform: uppercase;
				line-height: 20px;
				@media screen and (min-width: 992px) {
				margin-bottom: 0;
				font-size: 20px;
				line-height: 30px;
				}
			}
			
			.player-team-name,
			.player-field-position {}
			
			.player-team-name {
				@media screen and (min-width: 992px) {}
				&:hover {
					color: var(--primary-special-color);
				}
			}
			
			.player-field-number {
				
				position: relative;
				@media screen and (min-width: 992px) {
				padding-left: 10px;
				padding-right: 90px;
				}
				
				.field-number {
					margin-left: 5px;
					@media screen and (min-width: 992px) {
						display: flex;
						justify-content: center;
						position: absolute;
						top: -25px;
						right: 0;
						width: 80px;
						height: 80px;
						margin-left: 0;
						font-size: 40px;
						line-height: 80px;
						border: 2px solid #FFF;
						border-radius: 80px;
					}
				}
				
			}
			
		}
		
		.player-name {
			
			margin-top: 5px;
			text-transform: uppercase;
			
			@media screen and (min-width: 992px) {
			margin-top: 20px;
			}
			
			@media screen and (max-width: 991px) {}
			
			.player-full-name {
				
				padding: 0 10px;
				font-size: 18px;
				font-weight: 500;
				line-height: 30px;
				color: var(--white-color);
				
				@media screen and (min-width: 992px) {
				padding: 0 25px 0 35px;
				font-size: 45px;
				font-weight: 500;
				line-height: 55px;
				border-left: 2px solid #FFF;
				}
				
				span {
					
					margin-right: 5px;
					
					@media screen and (min-width: 992px) {
					display: block;
					margin-right: 0;
					}
					
					&.nick-name {
						color: var(--default-border-color);
					}
					
				}
			}
		}
		
	}
	
}


#player-information-data-component {
	
	margin-top: 10px;
	padding: 0 0;
	
	.main-section-content {
		justify-content: center;
	}
	
	.message-component {
		margin-right: 0;
		@media screen and (min-width: 992px) {
		border-right: 2px solid var(--white-color);
		}
	}
	
	@media screen and (min-width: 992px) {
	margin-top: 0;
	padding: 0;
	background: var(--secondary-color);
	border-top: none;
	border-bottom: 10px solid var(--primary-color);
	}
	
	.player-information-statistics-component,
	.player-information-description-component {
		background: var(--secondary-color);
		@media screen and (min-width: 992px) {
		background: none;
		}
	}
	
	.player-information-statistics-content,
	.player-information-description-content {
		@media screen and (min-width: 992px) {
		height: 100%;
		align-items: center;
		}
	}
	
	.player-information-component {
		
		padding: 5px;
		text-align: center;
		text-transform: uppercase;
		border: 1px solid #FFF;
		
		@media screen and (min-width: 992px) {
		height: 100%;
		padding: 5px 15px;
		border: none;
		//border-left: 2px solid #FFF;
		border-right: 2px solid #FFF;
		}
		
		@media screen and (min-width: 992px) {
		
		}
		
		.player-information-content {
			flex-direction: column;
			&:first-child {
				padding-bottom: 5px;
			}
		}
		
		.information-label,
		.information-value {
			
			min-height: 20px;
			color: var(--white-color);
			line-height: 20px;
			
			@media screen and (min-width: 992px) {
			min-height: 30px;
			line-height: 30px;
			}
			
		}
		
		.information-label {
			font-size: 10px;
			@media screen and (min-width: 992px) {
			font-size: 14px;
			}
			@media screen and (min-width: 1366px) {
			font-size: 18px;
			}
		}
		
		.information-value {
			font-size: 16px;
			font-weight: 600;
			@media screen and (min-width: 992px) {
			font-size: 20px;
			}
			@media screen and (min-width: 1366px) {
			font-size: 24px;
			}
		}
		
	}
	
	.player-information-statistics-component {
		
		.player-information-component {
			
			display: flex;
			justify-content: center;
			align-items: center;
			align-content: center;
			
			@media screen and (min-width: 992px) {}
			
			&:first-child {
				border-left: 2px solid #FFF;
			}
			
			.player-information-content {
				width: calc(100% + 30px);
				padding-bottom: 0;
			}
			
			.information-label {
				@media screen and (min-width: 992px) {
				margin-bottom: 5px;
				padding-bottom: 15px;
				border-bottom: 2px solid #FFF;
				}
			}
			
			.information-value {
				padding-top: 4px;
				@media screen and (min-width: 992px) {
				padding-top: 10px;
				}
			}
			
		}
		
	}
	
	.player-information-description-component {
		
		height: 100%;
		
		@media screen and (min-width: 992px) {}
		
		.player-information-component {
			@media screen and (min-width: 992px) {}
		}
		
		.player-information-content {
			
			@media screen and (max-width: 991px) {
			min-height: 50px;
			margin: 0 -5px;
			}
			
			&:first-child {}
			
			&:last-child {
				
				padding-top: 5px;
				
				@media screen and (max-width: 991px) {
				min-height: 51px;
				margin-top: -1px;
				}
				
				@media screen and (min-width: 992px) {
				}
				border-top: 2px solid #FFF;
			}
		}
		
	}
	
}


#player-details-trophies-component {
	
	margin-top: 10px;
	padding-bottom: 20px;
	text-transform: uppercase;
	color: var(--white-color);
	font-size: 13px;
	@media screen and (min-width: 992px) {
		margin-top: 30px;
		padding-bottom: 0;
		font-size: 16px;
	}
	
	
	
}


#player-details-description-component {
	
	margin-top: 10px;
	padding-bottom: 20px;
	@media screen and (min-width: 992px) {
		margin-top: 10px;
		padding-bottom: 30px;
	}
	
	.player-description-header,
	.player-description-content {}
	
	
	
	.player-description-content {
		//margin: 25px 0;
		color: var(--white-color);
		@media screen and (min-width: 992px) {
			//margin: 50px 0;
		}
	}
	
	
	.player-statistics-table {
	
	
	
	}
	
	
	
	.player-trophies-and-titles {
		margin-bottom: 25px;
		@media screen and (min-width: 992px) {
			margin-bottom: 50px;
		}
		.description-element-header,
		.trophies-and-titles-list {}
		.trophies-and-titles-list {
			padding: 5px 10px;
			font-size: 14px;
			font-weight: 500;
			color: var(--white-color);
			text-transform: uppercase;
			@media screen and (min-width: 992px) {
				padding: 10px;
				font-size: 16px;
			}
		}
	}
	
}


.player-games-component {
	
	margin-bottom: 50px;
	
	.player-games-header {
		margin-bottom: 20px;
		h2 {
			font-size: 20px;
			font-weight: 600;
			color: var(--white-color);
			text-transform: uppercase;
		}
	}
	
	.player-games-game {
		
		margin-bottom: 15px;
		padding-bottom: 15px;
		border-bottom: 1px solid #FFF;
		
		&:last-child {
			margin-bottom: 0;
			padding-bottom: 0;
			border-bottom: none;
		}
		
		.player-game-teams,
		.player-game-statistics {}
		
		.player-game-teams {
			
			.game-teams-points,
			.game-teams-names {
				font-size: 14px;
				font-weight: 600;
				text-align: center;
				line-height: 24px;
			}
			
			.game-teams-points {
				color: var(--white-color);
				span {
					display: block;
				}
			}
			
			.game-teams-names {
				padding-left: 0;
				text-align: left;
				a {
					display: block;
					color: var(--white-color);
				}
			}
			
		}
		
		.player-game-statistics {
			
			@media screen and (min-width: 992px) {
				padding: 0 15px 0 0;
			}
			
			.player-game-statistics-content {
				justify-content: space-between;
			}
			
			.player-game-statistic {
				
				padding: 0 5px;
				min-width: 6%;
				//border-right: 1px solid #FFF;
				font-size: 14px;
				font-weight: 700;
				color: #FFF;
				text-align: center;
				line-height: 24px;
				text-transform: uppercase;
				
				&:last-child {
					border-right: none;
				}
				
				span {
					display: block;
					&.w {
						color: #00FF3A;
					}
					&.l {
						color: #F9172C;
					}
				}
				
			}
			
		}
		
	}
	
}


.player-details-section-switcher {
	
	margin: 15px 0 25px;
	padding: 0 15px;
	//background: var(--primary-color);
	//border-top: 2px solid var(--secondary-color);
	//border-bottom: 2px solid var(--secondary-color);
	
	@media screen and (min-width: 992px) {
	margin: 50px 0 35px;
	padding: 0 15px;
	border-top: none;
	border-bottom: none;
	}
	
	.player-details-section-switcher-content {
		justify-content: center;
		border-bottom: 5px solid var(--primary-color);
	}
	
	.section-switcher {
		
		padding: 0 15px 0;
		@media screen and (min-width: 992px) {
		padding: 0 50px 15px;
		}
		
		.section-switcher-content {
			
			@media screen and (min-width: 992px) {
			justify-content: space-between;
			}
			
		}
		
		.section-switcher-button {
			
			position: relative;
			height: 40px;
			padding: 10px 10px;
			font-size: 14px;
			color: #FFF;
			text-align: center;
			text-transform: uppercase;
			line-height: 20px;
			cursor: pointer;
			background: var(--primary-color);
			border: 2px solid #FFF;
			
			@media screen and (min-width: 992px) {
			height: 50px;
			margin-right: 100px;
			padding: 10px 50px;
			font-size: 28px;
			line-height: 30px;
			background: none;
			border: none;
			}
			
			&:last-child {
				margin-right: 0;
			}
			
			&:hover {
				color: var(--primary-special-color);
			}
			
			span {
				display: block;
				line-height: 18px;
				@media screen and (min-width: 992px) {
				line-height: 30px;
				}
			}
			
			&.selected {
				
				font-weight: 600;
				color: #FFFFFF;
				background: var(--primary-special-color);
				
				@media screen and (min-width: 992px) {
				color: var(--primary-color);
				background: #FFF;
				//border-left: 20px solid var(--primary-special-color);
				//border-right: 20px solid var(--primary-special-color);
				border-bottom: 3px solid var(--primary-special-color);
				box-shadow: 10px 10px 5px var(--primary-color);
				}
				
			}
			
		}
		
	}
	
}


#player-details-data {
	.message-component {
		margin: 0 auto;
	}
	padding-bottom: 50px;
}


.player-details-statistics {
	
	.player-details-statistics-content {}
	
	.player-statistics-table {
		
		margin-bottom: 25px;
		padding: 0 20px;
		text-transform: uppercase;
		
		@media screen and (min-width: 992px) {
		margin-bottom: 100px;
		padding: 0 15px;
		}
		
		&:last-child {
			//margin-bottom: 0;
		}
		
		.player-statistics-table-header {
			
			.statistics-table-header {
				
				position: relative;
				width: 100%;
				font-size: 16px;
				color: #FFF;
				line-height: 35px;
				background: var(--primary-color);
				border-bottom: 2px solid var(--primary-special-color);
				
				@media screen and (min-width: 992px) {
				width: auto;
				min-width: 30%;
				font-size: 22px;
				font-weight: 600;
				text-align: center;
				line-height: 50px;
				border-bottom-width: 5px;
				}
				
				.bg {
					
					display: none;
					
					@media screen and (min-width: 992px) {
					display: block;
					}
					
					position: absolute;
					top: 0;
					width: 15px;
					height: 45px;
					
					&.bg_l {
						right: 0;
						border-top: 55px solid transparent ;
						border-right: 15px solid #DA5E15;
					}
					
					&.bg_r {
						right: -15px;
						border-top: 55px solid #DA5E15 ;
						border-right: 15px solid transparent;
					}
					
				}
				
			}
			
		}
		
		.statistics-table-row {
			
			position: relative;
			line-height: 20px;
			
			@media screen and (min-width: 992px) {
			line-height: 20px;
			height: 50px;
			}
			
			.statistics-table-row-content {
				justify-content: center;
				align-items: center;
				align-content: center;
				height: 100%;
				margin: 0;
			}
			
			.statistics-table-cell {
				
				padding: 0;
				font-weight: 600;
				text-align: center;
				justify-content: center;
				
				@media screen and (min-width: 992px) {
				flex: 0 0 2.5%;
				min-width: 2.5%;
				max-width: 2.5%;
				}
				
				span.separator {
					display: inline-block;
					vertical-align: top;
					width: 1px;
					height: 20px;
					margin: 0 5px;
					background: #FFF;
				}
				
				&.season-name-data {
					@media screen and (min-width: 992px) {
					flex: 0 0 8%;
					min-width: 8%;
					max-width: 8%;
					}
				}
				
				&.team-name-data {
					@media screen and (min-width: 992px) {
					flex: 0 0 14%;
					min-width: 14%;
					max-width: 14%;
					}
				}
				
				&.games-number-data,
				&.start-5-data {
					@media screen and (min-width: 992px) {
					flex: 0 0 3%;
					min-width: 3%;
					max-width: 3%;
					}
				}
				
				&.minutes-number-data {
					@media screen and (min-width: 992px) {
					flex: 0 0 5%;
					min-width: 5%;
					max-width: 5%;
					}
				}
				
				&.points-number-data {
					@media screen and (min-width: 992px) {
					flex: 0 0 4%;
					min-width: 4%;
					max-width: 4%;
					}
				}
				
				&.pt2-number-data,
				&.pt3-number-data,
				&.pt-game-number-data,
				&.ft-number-data {
					@media screen and (min-width: 992px) {
					flex: 0 0 8.5%;
					min-width: 8.5%;
					max-width: 8.5%;
					}
				}
				
				&.rebounds-number-data {
					@media screen and (min-width: 992px) {
					flex: 0 0 7%;
					min-width: 7%;
					max-width: 7%;
					}
				}
				
				&.assists-number-data,
				&.blocks-number-data,
				&.steals-number-data,
				&.turnovers-number-value,
				&.fouls-number-data {
					@media screen and (min-width: 992px) {
					flex: 0 0 3%;
					min-width: 3%;
					max-width: 3%;
					}
				}
				
				&.effectivity-value-data {
					@media screen and (min-width: 992px) {
					flex: 0 0 4%;
					min-width: 4%;
					max-width: 4%;
					}
				}
				
				&.eval-value-data {
					@media screen and (min-width: 992px) {
					flex: 0 0 3%;
					min-width: 3%;
					max-width: 3%;
					}
				}
				
			}
			
		}
		
		.statistics-table-head {
			
			display: none;
			padding: 5px 0;
			color: #FFF;
			background: var(--primary-color);
			
			@media screen and (min-width: 992px) {
			display: block;
			}
			
			.statistics-table-cell {
				
				&.team-name-data {
					padding: 15px 0;
					background: var(--secondary-color);
				}
				
			}
			
		}
		
		.statistics-table-data {
			
			margin-top: 5px;
			padding: 0;
			background: #FFF;
			border: 1px solid var(--primary-color);
			
			@media screen and (min-width: 992px) {
			margin-top: 0;
			border: none;
			}
			
			&:nth-child(odd) {
				background: #E6E6E6;
			}
			
			.statistics-table-cell {
				
				
				min-height: 40px;
				border-right: 1px solid var(--default-border-color);
				border-bottom: 1px solid var(--default-border-color);
				
				@media screen and (max-width: 991px) {
				display: flex;
				flex-direction: column;
				}
				
				@media screen and (min-width: 992px) {
				padding: 15px 0;
				border-bottom: none;
				}
				
				&:last-child {
					border-right: none;
				}
				
				&.points-number-data, &.ft-number-data {
					@media screen and (max-width: 991px) {
					border-right: none;
					}
				}
				
				span.separator {
					background: var(--primary-color);
				}
				
				.statistic-label,
				.statistic-value {
					display: block;
				}
				
				.statistic-label {
					
					font-size: 10px;
					font-weight: 500;
					color: #FFF;
					background: var(--secondary-color);
					
					@media screen and (min-width: 992px) {
					display: none;
					}
					
				}
				
				.statistic-value {
					
					font-size: 10px;
					
					@media screen and (min-width: 992px) {
					font-size: 12px;
					}
					
				}
				
			}
			
		}
		
	}
	
}


.player-details-played-games {
	
	.player-details-played-games-content {}
	
	.played-games-header {
		
		position: relative;
		width: 100%;
		padding: 5px 15px;
		font-size: 16px;
		color: #FFF;
		text-transform: uppercase;
		background: var(--primary-color);
		border-bottom: 2px solid var(--primary-special-color);
		line-height: 25px;
		
		@media screen and (min-width: 992px) {
		width: auto;
		height: 55px;
		padding: 10px 15px;
		font-size: 20px;
		line-height: 30px;
		background: var(--primary-color);
		border-bottom: 5px solid #DA5E15;
		}
		
		.played-games-header-content {}
		
		.played-games-header-label {
			
			padding-right: 5px;
			
			@media screen and (min-width: 992px) {
			padding-right: 15px;
			}
			
		}
		
		.played-games-leagues-list {
			
			position: relative;
			padding: 0;
			
			.played-games-active-league {
				//color: #017A3B;
				padding-right: 15px;
				
				@media screen and (min-width: 992px) {
				padding-right: 30px;
				}
				
				span {
					
					margin-right: 5px;
					
					@media screen and (min-width: 992px) {
					margin-right: 10px;
					}
					
					&:last-child {
						margin-right: 0;
					}
				}
			}
			
			.played-games-leagues {
				
				position: absolute;
				top: 40px;
				left: 0;
				width: auto;
				min-width: calc(100% + 65px);
				padding: 10px 0;
				background: #FFF;
				border: 1px solid var(--primary-color);
				z-index: 10000;
				
				@media screen and (min-width: 992px) {
				
				}
				
				.played-games-league {
					cursor: pointer;
					color: var(--primary-color);
					&:hover {
						color: var(--primary-special-color);
					}
				}
				
			}
			
		}
		
		.played-games-leagues-switcher {
			span {
				display: flex;
				height: 100%;
				align-items: center;
				cursor: pointer;
				svg {
					vertical-align: top;
				}
			}
		}
		
		.played-games-leagues-bg-1,
		.played-games-leagues-bg-2 {
			
			display: none;
			position: absolute;
			top: 0;
			width: 10px;
			height: 55px;
			padding: 0;
			
			@media screen and (min-width: 992px) {
			display: block;
			}
			
		}
		
		.played-games-leagues-bg-1 {
			right: 0;
			border-top: 55px solid transparent ;
			border-right: 20px solid #DA5E15;
		}
		
		.played-games-leagues-bg-2 {
			right: -20px;
			border-top: 55px solid #DA5E15;
			border-right: 20px solid transparent;
		}
		
	}
	
	
	.played-games-list {
		
		position: relative;
		margin-bottom: 25px;
		padding: 0 20px;
		font-weight: 500;
		padding: 0 20px;
		text-transform: uppercase;
		
		@media screen and (min-width: 992px) {
		margin-bottom: 50px;
		padding: 0 15px;
		}
		
		.league-game-info {
			
			position: relative;
			
			min-height: 40px;
			padding: 0;
			text-align: center;
			justify-content: center;
			align-content: center;
			align-items: center;
			
			@media screen and (min-width: 992px) {
			height: 100%;
			padding: 15px 0;
			}
			
			&.league-game-date {
				@media screen and (min-width: 992px) {
				flex: 0 0 7.5%;
				width: 7.5%;
				max-width: 7.5%;
				}
			}
			
			&.league-game-opponent-team {
				@media screen and (min-width: 992px) {
				flex: 0 0 22%;
				width: 22%;
				max-width: 22%;
				}
			}
			
			&.league-game-result {
				@media screen and (min-width: 992px) {
				flex: 0 0 5%;
				width: 5%;
				max-width: 5%;
				}
			}
			
			&.league-game-first-squad {
				@media screen and (min-width: 992px) {
				flex: 0 0 2.5%;
				width: 2.5%;
				max-width: 2.5%;
				}
			}
			
			&.league-game-played-time {
				@media screen and (min-width: 992px) {
				flex: 0 0 4%;
				width: 4%;
				max-width: 4%;
				}
			}
			
			&.league-game-points {
				@media screen and (min-width: 992px) {
				flex: 0 0 3%;
				width: 3%;
				max-width: 3%;
				}
			}
			
			&.league-game-2pts, &.league-game-3pts, &.league-game-gpts, &.league-game-1pts {
				@media screen and (min-width: 992px) {
				flex: 0 0 7.5%;
				width: 7.5%;
				max-width: 7.5%;
				}
			}
			
			&.league-game-rebounds {
				@media screen and (min-width: 992px) {
				flex: 0 0 6%;
				width: 6%;
				max-width: 6%;
				}
			}
			
			&.league-game-assists,
			&.league-game-blocks,
			&.league-game-steals,
			&.league-game-fouls,
			&.league-game-turnovers {
				@media screen and (min-width: 992px) {
				flex: 0 0 2.5%;
				width: 2.5%;
				max-width: 2.5%;
				}
			}
			
			&.league-game-eff {
				@media screen and (min-width: 992px) {
				flex: 0 0 3.5%;
				width: 3.5%;
				max-width: 3.5%;
				}
			}
			
			&.league-game-eval {
				@media screen and (min-width: 992px) {
				flex: 0 0 3.5%;
				width: 3.5%;
				max-width: 3.5%;
				}
			}
			
			span.sep {
				display: inline-block;
				vertical-align: top;
				width: 1px;
				height: 16px;
				margin: 2px 4px;
				background: #FFF;
				@media screen and (min-width: 992px) {}
			}
			
		}
		
		.played-games-list-head {
			
			display: none;
			color: #FFF;
			background: var(--primary-color);
			border: 1px solid var(--primary-color);
			
			@media screen and (min-width: 992px) {
			display: block;
			}
			
			.played-games-list-head-content {
				align-items: center;
			}
			
			.league-game-info {
				
				padding: 5px 0;
				
				&.league-game-opponent-team {
					height: 58px;
					line-height: 48px;
					background: var(--secondary-color);
				}
				
				span.lab {
					display: block;
				}
				
			}
			
		}
		
		.played-games-list-game {
			
			margin-top: 10px;
			background: #FFF;
			border: 1px solid var(--primary-color);
			
			@media screen and (min-width: 992px) {
			margin-top: 0;
			border: none;
			}
			
			&:nth-child(odd) {
				background: #E6E6E6;
			}
			
			.league-game-info {
				
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-content: center;
				align-items: center;
				border-right: 1px solid #CCC;
				
				&:last-child {
					border-right: none;
				}
				
				&.league-game-opponent-team, &.league-game-result {
					line-height: 15px;
					@media screen and (min-width: 992px) {
					line-height: 20px;
					}
				}
				
				&.league-game-result {
					
					span, a {
						color: #FF0000;
					}
					
					@media screen and (min-width: 992px) {
					padding: 5px 0;
					}
					
					&.winner {
						span, a {
							color: #1E7E34;
						}
					}
					
					span {
						display: block;
					}
					
				}
				
				&.league-game-first-squad {
					
					span {
						
						svg {
							margin-top: -5px;
							color: #FF0000;
						}
						
						&.first-squad {
							svg {
								color: #1E7E34;
							}
						}
						
					}
				}
				
				&.league-game-points, &.league-game-1pts {
					border-right: none;
				}
				
				span.sep {
					background: var(--primary-color);
				}
				
				.statistic-label,
				.statistic-value {
					display: block;
					width: 100%;
				}
				
				
				.statistic-label {
					font-size: 10px;
					color: #FFF;
					background: var(--secondary-color);
					@media screen and (min-width: 992px) {
					display: none;
					}
				}
				
				.statistic-value {
					font-size: 11px;
					font-weight: 600;
					@media screen and (min-width: 992px) {
					font-size: 12px;
					}
				}
				
				&.league-game-date {
					.statistic-value {
						font-size: 9px;
						@media screen and (min-width: 992px) {
							font-size: 12px;
						}
					}
				}
				
			}
			
		}
		
	}
	
}


.player-details-best-scores {
	
	position: relative;
	
	.best-scores-header {
		
		position: relative;
		
		.best-scores-header-label,
		.best-scores-header-select {
			
			height: 35px;
			background: var(--primary-color);
			border-bottom: 2px solid var(--primary-special-color);
			
			@media screen and (min-width: 992px) {
			height: 55px;
			border-bottom-width: 5px;
			}
			
		}
		
		.best-scores-header-label {
			
			padding: 5px 15px;
			font-size: 16px;
			color: #FFF;
			text-transform: uppercase;
			line-height: 25px;
			
			@media screen and (min-width: 992px) {
			font-size: 18px;
			text-transform: uppercase;
			line-height: 40px;
			}
			
		}
		
		.best-scores-header-select {
			
			position: relative;
			padding-left: 0;
			
			
			@media screen and (min-width: 992px) {
			padding-right: 30px;
			}
			
			.form-control {
				
				height: 35px;
				font-size: 14px;
				color: #FFF;
				text-transform: uppercase;
				background: none;
				border: none;
				border-radius: 0;
				
				@media screen and (min-width: 992px) {
				height: 50px;
				font-size: 18px;
				}
				
				option {
					border-radius: 0;
					color: #222;
					background: #FFF;
				}
				
				&:disabled {
					appearance: none;
				}
				
			}
			
			&::before, &::after {
				
				display: none;
				content: "";
				position: absolute;
				top: 0;
				width: 10px;
				height: 55px;
				
				@media screen and (min-width: 992px) {
				display: block;
				}
				
			}
			
			&::before {
				right: 0;
				border-top: 55px solid transparent;
				border-right: 10px solid var(--primary-special-color);
			}
			
			&::after {
				right: -10px;
				border-top: 55px solid var(--primary-special-color);
				border-right: 10px solid transparent;
			}
			
		}
		
	}
	
	.player-best-scores-table {
		
		position: relative;
		margin-bottom: 25px;
		color: #2A2A2A;
		margin-top: 10px;
		padding: 0 20px;
		font-weight: 600;
		
		@media screen and (min-width: 992px) {
		margin-top: 30px;
		margin-bottom: 50px;
		padding: 0 15px;
		}
		
		.best-scores-table-header {
			
			display: none;
			position: relative;
			height: 50px;
			text-transform: uppercase;
			
			@media screen and (min-width: 992px) {
			display: block;
			}
			
			.best-score-value,
			.best-score-game-date,
			.best-score-opponent,
			.best-score-result {
				font-size: 14px;
				font-weight: 600;
				color: #FFF;
				text-align: center;
				background: var(--primary-color);
				line-height: 50px;
			}
			
		}
		
		.player-best-scores-table-score {
			
			font-size: 11px;
			line-height: 20px;
			background: #FFF;
			border-bottom: 1px solid var(--default-border-color);
			
			@media screen and (min-width: 992px) {
			height: 50px;
			font-size: 14px;
			line-height: 30px;
			border-bottom: none;
			}
			
			&:nth-child(even) {
				background: #EBEBEB;
			}
			
			&:last-child {
				border-bottom: none;
			}
			
			.best-score-label {
				
				padding: 5px 15px;
				text-align: right;
				text-transform: uppercase;
				border-right: 1px solid var(--default-border-color);
				
				@media screen and (min-width: 992px) {
				padding: 10px 15px;
				}
				
			}
			
			.best-score-data {
				
				.best-score-data-content {
					justify-content: center;
				}
				
				.best-score-value,
				.best-score-game-date,
				.best-score-opponent-team,
				.best-score-game-result {
					
					padding: 5px 5px;
					text-align: center;
					
					@media screen and (min-width: 992px) {
					padding: 10px 15px;
					border-left: 3px solid #CCC;
					}
					
					span {
						
						margin-right: 5px;
						
						@media screen and (min-width: 992px) {
						margin-right: 10px;
						}
						&:last-child {
							margin-right: 0;
						}
						
					}
					
				}
				
				.best-score-value {
					
					font-size: 12px;
					font-weight: 600;
					color: #FFF;
					background: var(--secondary-color);
					border-right: 1px solid var(--default-border-color);
					
					@media screen and (min-width: 992px) {
					font-size: 12px;
					color: #2A2A2A;
					background: none;
					border-right: 3px solid var(--default-border-color);
					}
					
				}
				
				.best-score-value, .best-score-game-date {}
				
				.best-score-game-date {
					font-size: 10px;
					font-weight: 500;
					@media screen and (min-width: 992px) {
					font-size: 12px;
					font-weight: 600;
					}
				}
				
				.best-score-opponent-team {
					padding-right: 0;
					@media screen and (min-width: 992px) {
					padding-right: 15px;
					}
				}
				
				a {
					color: #2A2A2A;
					&:hover {
						color: var(--primary-special-color);
					}
				}
				
			}
			
		}
		
	}
	
}
