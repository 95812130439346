.leagues-schedules {
	
	&__league-rounds-list {
		
		&__league_round {
			
			&__games-list {
				
				&__game {
					
					padding-bottom: 10px;
					margin-bottom: 10px;
					font-weight: 600;
					justify-content: space-between;
					border-bottom: 1px solid #E5E5E5;
					
					@media screen and (min-width: 992px) {
					width: calc(100% + 30px);
					margin: 0 -15px 20px;
					padding-bottom: 0;
					border-bottom: none;
					}
					
					&:last-child {
						border-bottom: none;
						@media screen and (min-width: 992px) {
						margin-bottom: 0;
						padding-bottom: 0;
						}
					}
					
					.game-home-team-name,
					.game-away-team-name,
					.game-teams-points,
					.game-teams-separator {
						line-height: 18px;
					}
					
					.game-home-team-name,
					.game-away-team-name,
					.game-teams-separator {
						padding: 0;
					}
					
					.game-home-team-name,
					.game-home-team-points {
						text-align: right;
					}
					
					.game-away-team-name,
					.game-away-team-points {
						text-align: left;
					}
					
					.game-home-team-name,
					.game-away-team-name {
						
						max-width: 47%;
						font-weight: 400;
						
						@media screen and (min-width: 992px) {
							flex: 0 0 30%;
							max-width: 30%;
						}
						
						&.winner {
							font-weight: 700;
						}
						
						a {
							display: block;
							color: #000;
							&:hover {
								color: var(--primary-special-color);
							}
						}
						
						
					}
					
					.game-home-team-name {
						padding-right: 0;
						padding-left: 5px;
						@media screen and (min-width: 992px) {
						padding-left: 0;
						}
					}
					
					.game-away-team-name {
						padding-left: 0;
						padding-right: 5px;
					}
					
					.game-teams-points {
						padding: 0 5px;
						text-align: center;
						a {
							display: block;
							span.points-separator {
								margin: 0 5px;
							}
						}
					}
					
					.game-home-team-points,
					.game-away-team-points {
						padding: 0 5px;
						@media screen and (min-width: 992px) {
							flex: 0 0 4.333333%;
						}
					}
					
					.game-teams-separator {
						text-align: center;
						@media screen and (min-width: 992px) {
							flex: 0 0 1%;
						}
					}
					
					.game-date-time {
						
						text-align: center;
						justify-content: center;
						margin-bottom: 5px;
						order: -1;
						
						@media screen and (min-width: 992px) {
						flex: 0 0 15%;
						margin-top: 0;
						padding: 0 0 0 10px;
						text-align: left;
						order: 12;
						font-size: 12px;
						line-height: 18px;
						}
						
						.social-stream-link {
							margin-left: 3px;
							float: right;
							&:hover {
								opacity: 0.7;
							}
							svg {
								margin-top: -4px;
								width: 14px;
								height: 14px;
							}
						}
						
					}
					
					.game-arena {
						
						margin-top: 5px;
						padding: 0;
						text-align: center;
						
						@media screen and (min-width: 992px) {
						flex: 0 0 15%;
						max-width: 15%;
						margin-top: 0;
						text-align: left;
						font-size: 12px;
						line-height: 18px;
						}
						
					}
					
				}
				
			}
			
		}
		
	}
	
}
