.user-profile-page {
	
	position: relative;
	
	.user-profile-page-content {
		
		position: relative;
		text-transform: uppercase;
		
		.user-profile-page-content-content {
			align-items: flex-start;
			align-content: flex-start;
		}
		
		.user-profile-page-data {
			
			position: relative;
			
			.user-profile-page-data-header {
				margin-bottom: 10px;
				padding: 0 0 10px;
				font-size: 16px;
				font-weight: 600;
				color: var(--primary-color);
				border-bottom: 1px solid var(--primary-color);
			}
			
			.user-profile-formals {
				padding: 0;
			}
			
			.user-data-information-row {
				
				padding: 10px 15px;
				background: #FFF;
				
				&:nth-child(odd) {
					background: #E6E6E6;
				}
				
				.data-label {
					margin-right: 10px;
				}
				
				.data-value {
					font-weight: 600;
				}
				
			}
			
			.user-main-data {
				
				.user-main-data-content {
					align-items: start;
				}
				
				.user-profile-image {
					
					position: relative;
					margin-bottom: 10px;
					padding: 0 15px;
					//padding: 15px 30px;
					//text-align: center;
					//background: #FFF;
					
					@media screen and (min-width: 992px) {
					margin-bottom: 0;
					padding: 0;
					}
					
					.user-profile-image-picture {
						
						position: relative;
						max-width: 100px;
						margin-bottom: 10px;
						padding: 5px;
						justify-content: center;
						align-content: center;
						align-items: center;
						text-align: center;
						background: #FFF;
						border: 1px solid #CCC;
						
						@media screen and (min-width: 992px) {
						margin-bottom: 0;
						padding: 15px;
						max-width: none;
						min-height: 182px;
						}
						
						img {
							max-height: 80px;
							@media screen and (min-width: 992px) {
							max-height: 150px;
							}
						}
						
					}
					
					.change-profile-image {
						
						position: absolute;
						top: 5px;
						right: 5px;
						width: 20px;
						height: 20px;
						background: #FFF;
						border: 1px solid #CCC;
						z-index: 999;
						cursor: pointer;
						
						@media screen and (min-width: 992px) {
						top: 15px;
						right: 15px;
						}
						
						&:hover {
							color: var(--primary-special-color);
						}
						
						svg {
							width: 16px;
							height: 16px;
							margin: 1px;
							vertical-align: top;
						}
						
					}
					
					img {
						max-height: 150px;
					}
					
					.user-profile-image-uploader {
						
						margin-top: 10px;
						
						.form-field {
							
							@media screen and (min-width: 992px) {
							text-align: center;
							}
							
							.form-field-content {
								@media screen and (min-width: 992px) {
								justify-content: center;
								}
							}
							
							.form-control-file {
								
								width: 100%;
								max-width: 100%;
								padding: 0;
								
								@media screen and (min-width: 992px) {
								width: 100px;
								max-width: 100px;
								padding: 0;
								}
								
							}
							
						}
						
						.file-information {
							
							padding: 0;
							overflow: hidden;
							font-size: 9px;
							
							@media screen and (min-width: 992px) {
							font-size: 12px;
							}
							
							span {
								float: left;
							}
							
							.save-file {
								
								margin-right: 5px;
								cursor: pointer;
								
								&:hover {
									color: var(--primary-special-color);
								}
								
								svg {
									
									width: 15px;
									height: 15px;
									vertical-align: top;
									
									@media screen and (min-width: 992px) {
									width: 18px;
									height: 18px;
									margin-top: 2px;
									}
									
								}
								
							}
							
							.file-name {
								
								max-width: calc(100% - 23px);
								line-height: 15px;
								
								@media screen and (min-width: 992px) {
								line-height: 24px;
								}
								
							}
							
						}
						
					}
					
				}
				
				.user-main-information {
				
					padding-left: 30px;
					@media screen and (min-width: 992px) {
					padding-left: 15px;
					}
					
				}
				
				.user-contact-information {
					
					margin-top: 20px;
					padding-top: 10px;
					border-top: 1px solid var(--default-border-color);
					
					@media screen and (min-width: 992px) {
					margin-top: 0;
					padding-top: 0;
					border-top: none;
					}
					
					
				}
				
			}
			
			&.user-profile-captain-area {
				
				.user-profile-captain-team {
					
					margin-bottom: 20px;
					padding-bottom: 20px;
					border-bottom: 1px solid #CCC;
					
					&:last-child {
						margin-bottom: 0;
						padding-bottom: 0;
						border-bottom: none;
					}
					
					.captain-team-data {
						
						.team-logo {
							
							position: relative;
							
							.change-team-logo {
								
								position: absolute;
								top: 5px;
								right: 5px;
								width: 24px;
								height: 24px;
								padding: 2px;
								background: #FFF;
								border: 1px solid #CCC;
								
								svg {
									width: 18px;
									height: 18px;
									vertical-align: top;
									cursor: pointer;
									&:hover {
										color: var(--primary-special-color);
									}
								}
								
							}
							
							.team-logo-picture {
								
								padding: 15px;
								background: #FFF;
								justify-content: center;
								align-items: center;
								align-content: center;
								border: 1px solid #CCC;
								
								@media screen and (min-width: 992px) {
								height: 150px;
								}
								
								a {
									
									display: block;
									
									&:hover {
										overflow: hidden;
									}
									
								}
								
								img {
									max-height: 70px;
									@media screen and (min-width: 992px) {
									max-height: 120px;
									}
								}
								
							}
							
							.change-logo-picture {
								
								margin-top: 10px;
								
								.form-field {
									text-align: center;
									.form-field-content {
										justify-content: center;
									}
									.form-control-file {
										width: 100px;
										max-width: 100px;
										padding: 0;
									}
								}
								
								.file-information {
									
									padding: 0;
									overflow: hidden;
									
									.save-file {
										margin-right: 5px;
										cursor: pointer;
										&:hover {
											color: var(--primary-special-color);
										}
										svg {
											width: 18px;
											height: 18px;
											margin-top: 2px;
											vertical-align: top;
										}
									}
									
								}
								
							}
							
						}
						
						.team-main-data {
							
							.team-name-field,
							.team-city-field,
							.team-short-name-field {
							.form-field-text {
								
								font-size: 13px;
								color: var(--primary-special-color);
								
								@media screen and (min-width: 992px) {
								font-size: 16px;
								}
								
								}
							}
							
						}
						
						.team-information {
							
							margin: 10px 0 20px;
							padding: 10px 15px 0;
							border-top: 1px solid var(--default-border-color);
							
							@media screen and (min-width: 992px) {
							margin: 20px 0;
							padding: 20px 15px 10px;
							border-top: 1px solid var(--secondary-color);
							border-bottom: 1px solid var(--secondary-color);
							}
							
						}
						
						.team-description-field {
							.form-field-text {
								font-weight: 500;
							}
						}
						
					}
					
					.captain-team-header {
						
						margin-bottom: 10px;
						padding: 0;
						border-bottom: var(--secondary-color);
						
						.team-logo {
							margin-right: 10px;
							img {
								max-height: 50px;
							}
						}
						
						.team-name {
							font-weight: 600;
							color: var(--primary-special-color);
						}
						
						.team-city {
							margin-left: 5px;
						}
						
					}
					
					.captain-team-description {
						margin-bottom: 10px;
						padding-bottom: 10px;
						border-bottom: 1px solid #CCC;
					}
					
					.captain-team-zone-players {
						
						
						.captain-team-add-player-button {
							
							padding-top: 10px;
							border-top: 1px solid #CCC;
							
							.captain-team-add-player-button-content {
								
								justify-content: right;
								
								span {
									
									display: flex;
									font-weight: 600;
									align-items: center;
									align-content: center;
									cursor: pointer;
									
									&:hover {
										color: var(--primary-special-color);
									}
									
									svg {
										margin-left: 5px;
									}
									
								}
								
								
							}
							
						}
						
						.captain-team-add-player {
							
							margin-top: 20px;
							padding: 20px 0 0;
							border-top: 1px solid #CCC;
							
							.captain-team-add-player-content {
								margin: 0;
							}
							
							.captain-team-add-player-header {
								
								margin-bottom: 10px;
								padding: 0;
								font-weight: 600;
								
								@media screen and (min-width: 992px) {
								padding: 0 15px;
								}
								
								.add-player-header-close {
									
									float: right;
									cursor: pointer;
									color: #E2000F;
									
									@media screen and (min-width: 992px) {}
									
									&:hover {
										color: var(--primary-special-color);
									}
									
								}
								
								svg {
									
									width: 15px;
									height: 15px;
									margin-right: 5px;
									vertical-align: top;
									
									@media screen and (min-width: 992px) {
									width: 18px;
									height: 18px;
									margin-top: 3px;
									margin-right: 0;
									}
									
								}
								
							}
							
							.form-field {
								
								margin-bottom: 5px;
								
								@media screen and (min-width: 992px) {
								margin-bottom: 10px;
								}
								
								.form-control {
									
									height: 25px;
									padding: 0 10px;
									font-size: 10px;
									
									@media screen and (min-width: 992px) {
									height: 30px;
									padding: 0 5px;
									font-size: 12px;
									}
									
									&::placeholder {
										font-size: 10px;
										@media screen and (min-width: 992px) {
										font-size: 12px;
										}
									}
									
								}
								
								&.submit-form-field {
									
									text-align: center;
									
									.form-field-content {
										justify-content: center;
									}
									
									.btn {
										height: 30px;
										padding: 0 25px;
										@media screen and (min-width: 992px) {
										height: 50px;
										padding: 10px 50px;
										}
									}
									
									@media screen and (min-width: 992px) {
									
									}
									
								}
								
							}
							
						}
						
					}
					
				}
				
			}
			
		}
		
	}
	
}


.user-form {
	
	
	&.border-top {
		margin-top: 10px;
		padding-top: 10px;
		border-top: 1px solid var(--default-border-color);
	}
	
	&.lg-no-border-top {
		@media screen and (min-width: 992px) {
		margin-top: 0;
		padding-top: 0;
		border-top: none!important;
		}
	}
	
	.form-field {
	
		overflow: hidden;
		margin-bottom: 5px;
		padding-bottom: 5px;
		border-bottom: 1px solid var(--default-border-color);
		
		@media screen and (min-width: 992px) {
		margin-bottom: 10px;
		padding-bottom: 0;
		border-bottom: none;
		}
		
		&:last-child {
		margin-bottom: 0;
		padding-bottom: 0;
		border-bottom: none;
		}
		
		&.no-margin-bottom {
			margin-bottom: 0;
			padding-bottom: 0;
		}
		
		&.no-border-bottom  {
			border-bottom: none;
		}
		
		
		.form-label {
			
			margin-bottom: 5px;
			font-size: 10px;
			font-weight: 500;
			text-transform: uppercase;
			line-height: 15px;
			
			@media screen and (min-width: 992px) {
			font-size: 12px;
			font-weight: 600;
			line-height: 20px;
			}
		
		}
		
		.form-field-text {
			
			padding: 0;
			font-size: 11px;
			font-weight: 600;
			line-height: 18px;
			
			@media screen and (min-width: 992px) {
			font-size: 13px;
			}
			
			span {
				line-height: 18px;
				@media screen and (min-width: 992px) {
				line-height: 24px;
				}
			}
			
		}
		
		.form-field-action {
			
			svg {
				
				width: 15px;
				height: 15px;
				vertical-align: top;
				
				@media screen and (min-width: 992px) {
				width: 18px;
				height: 18px;
				}
				
			}
			
		}
		
	}
	
}
